import React, { useState } from "react";
import { Stack, Typography, Zoom } from "@mui/material";
import Container from "../components/Container";
import GlassButton from "../components/GlassButton";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkExperience from "../components/About/WorkExperience";
import Studies from "../components/About/Studies";
import Skills from "../components/About/Skills";
import Awards from "../components/About/Awards";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

const About = () => {
  const [showWorkExperience, setShowWorkExperience] = useState(true);
  const [showStudies, setShowStudies] = useState(false);
  const [showSkills, setShowSkills] = useState(false);
  const [showAwards, setShowAwards] = useState(false);

  return (
    <Container sx={{ alignItems: "flex-start" }}>
      <Zoom in={true} timeout={200}>
        <Stack
          direction="column"
          alignItems="center"
          spacing={3}
          maxWidth="1200px"
        >
          <Typography variant="h3" component="h1">
            About
          </Typography>
          <Typography
            variant="p"
            component="p"
            textAlign="center"
            fontWeight={200}
            letterSpacing={0.2}
            lineHeight={1.2}
          >
            I'm an enthusiastic Software and Financial Engineer, passionate about
            writing code, generating, and developing new ideas in the most
            creative way to solve any problem. With knowledge in different areas
            such as: software development, cloud computing, machine learning models,
            web, mobile apps, finance, business administration and business management.
            <br></br>
            <br></br>
            I am structured, creative, love problem solving, organized and above all
            an easy-going person that gets along with others facilitating teamwork and work environment relations.
          </Typography>

          {/* about me buttons */}
          <Stack
            direction="row"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            spacing={1}
          >
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              <GlassButton>
                <HomeIcon />
              </GlassButton>
            </Link>
            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showWorkExperience}
              clickHandler={() => {
                setShowWorkExperience(!showWorkExperience);
                setShowStudies(false);
                setShowSkills(false);
                setShowAwards(false);
              }}
            >
              <WorkRoundedIcon sx={{ marginRight: 1 }} />
              <Typography variant="p" component="p">
                Work Experience
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showStudies}
              clickHandler={() => {
                setShowWorkExperience(false);
                setShowStudies(!showStudies);
                setShowSkills(false);
                setShowAwards(false);
              }}
            >
              <SchoolRoundedIcon sx={{ marginRight: 1 }} />
              <Typography variant="p" component="p">
                Studies
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showSkills}
              clickHandler={() => {
                setShowWorkExperience(false);
                setShowStudies(false);
                setShowSkills(!showSkills);
                setShowAwards(false);
              }}
            >
              <LightbulbIcon sx={{ marginRight: 1 }} />
              <Typography variant="p" component="p">
                Skills
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showAwards}
              clickHandler={() => {
                setShowWorkExperience(false);
                setShowStudies(false);
                setShowSkills(false);
                setShowAwards(!showAwards);
              }}
            >
              <EmojiEventsIcon sx={{ marginRight: 1 }} />
              <Typography variant="p" component="p">
                Awards & Certificates
              </Typography>
            </GlassButton>
          </Stack>

          {/* about me contents */}
          {showWorkExperience && <WorkExperience />}
          {showStudies && <Studies />}
          {showSkills && <Skills />}
          {showAwards && <Awards />}
        </Stack>
      </Zoom>
    </Container>
  );
};

export default About;
